<template>
    <div>
        <div class="table_box">
            <div style="background: white;padding-left: 20px;padding-top: 10px;padding-bottom: 10px;margin-bottom: 20px;border: 1px #eeeeee solid;border-radius: 4px">
                <el-row align="middle" type="flex" justify="end">
                    <el-button size="small" type="primary" style="margin-right: 16px;" @click="handleReturn">返回</el-button>
                </el-row>
                <el-row align="middle" type="flex" class="table-title">
                    <el-col style="width: 100px;text-align: left">
                        <div class="keyword">
                            <span>{{$t("m.customer.jibenxinxi")}}</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row type="flex">
                    <el-col v-if="result.account">
                        <el-row class="item">
                            <el-col :span="8">
                                <span>{{$t("m.customer.neicheng")}}：{{result.account.name}}</span>
                            </el-col>
                            <el-col :span="8">
                                <span>{{$t("m.customer.shoujuhao")}}：{{result.account.phone}}</span>
                            </el-col>
                            <el-col :span="8">
                                <span>{{$t("m.customer.zhuceshijian")}}：{{result.account.createTime}}</span>
                            </el-col>
                        </el-row>
                        <el-row class="item">
                            <el-col :span="8">
                                <span>{{$t("m.customer.youxian")}}：{{result.account.email}}</span>
                            </el-col>
                            <el-col :span="8">
                                <span>{{$t("m.customer.zhiye")}}：</span>
                                <span>{{result.account.profession}}</span>
                            </el-col>
                            <el-col :span="8">
                                <span>{{$t("m.customer.chengshi")}}：{{result.account.city}}</span>
                            </el-col>
                        </el-row>
                        <el-row class="item">
                            <el-col :span="8">
                                <span>openID：{{result.account.openId}}</span>
                            </el-col>
                            <el-col :span="8">
<!--                                class="whiteClass"-->
                                <span>{{$t("m.customer.qiyeyuzuzhi")}}：{{result.account.company}}</span>
<!--                                <span class="textOver"></span>-->
                            </el-col>
                            <el-col :span="8">
                                <span>{{$t("m.customer.jianjie")}}：{{result.account.description}}</span>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </div>

            <div style="background: white;padding-left: 20px;padding-top: 10px;padding-bottom: 10px;margin-bottom: 20px;border: 1px #eeeeee solid;border-radius: 4px">
                <el-row align="middle" type="flex" class="table-title">
                    <el-col style="width: 100px;text-align: left">
                        <div class="keyword">
                            <span>{{$t("m.customer.jiruqiye")}}</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row align="middle" type="flex" style="font-weight: 500;">
                    <el-col :span="6" type="flex">
                        <el-row align="middle" type="flex">
                            <el-col :span="2"></el-col>
                            <el-col>{{$t("m.customer.qiyemingcheng")}}</el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="6">
                        <el-row align="middle" type="flex">
                            <el-col :span="2"></el-col>
                            <el-col>{{$t("m.customer.xingming")}}</el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="6">
                        <el-row align="middle" type="flex">
                            <el-col :span="2"></el-col>
                            <el-col>{{$t("m.customer.role")}}</el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="6">
                        <el-row align="middle" type="flex">
                            <el-col :span="2"></el-col>
                            <el-col>{{$t("m.customer.jiruhuochaungjian")}}</el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row align="middle" type="flex" v-for="(item,index) in enterpriseList" :key="index" style="line-height: 40px;">
                    <el-col :span="6" type="flex">
                        <el-row align="middle" type="flex">
                            <el-col :span="2"></el-col>
                            <el-col style="white-space:nowrap;overflow: hidden;text-overflow:ellipsis;">{{item.merchantName}}</el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="6">
                        <el-row align="middle" type="flex">
                            <el-col :span="2"></el-col>
                            <el-col>
                                <span v-if="item.merchantAccountName">{{item.merchantAccountName}}</span>
                                <span v-else>-</span>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="6">
                        <el-row align="middle" type="flex">
                            <el-col :span="2"></el-col>
                            <el-col>
                                <span v-if="item.role!=null">{{roleList[item.role]}}</span>
                                <span v-else>-</span>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="6">
                        <el-row align="middle" type="flex">
                            <el-col :span="2"></el-col>
                            <el-col>
                                <span v-if="item.createTime">{{item.createTime}}</span>
                                <span v-else>-</span>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row type="flex" style="justify-content: flex-end;margin: 15px 0 0;">
                    <el-pagination class="pagination" background
                                   layout="total,prev, pager, next"
                                   :total="enterpriseTotal" :page-size="5"
                                   @current-change='current_enterpriseChange' style="margin-top: 10px">
                    </el-pagination>
                </el-row>
            </div>

            <div style="background: white;padding-left: 20px;padding-top: 10px;padding-bottom: 10px;margin-bottom: 20px;border: 1px #eeeeee solid;border-radius: 4px">
                <el-row align="middle" type="flex" class="table-title">
                    <el-col style="width: 200px;text-align: left">
                        <div class="keyword">
                            <span>{{$t("m.customer.xiezuo")}}</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row align="middle" type="flex" style="font-weight: 500;">
                    <el-col :span="5" type="flex">
                        <el-row align="middle" type="flex">
                            <el-col :span="2"></el-col>
                            <el-col>{{$t("m.customer.xiaochengxu")}}</el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="5">
                        <el-row align="middle" type="flex">
                            <el-col :span="2"></el-col>
                            <el-col>{{$t("m.customer.jiarushijian")}}</el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="5">
                        <el-row align="middle" type="flex">
                            <el-col :span="2"></el-col>
                            <el-col>{{$t("m.customer.role")}}</el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="5">
                        <el-row align="middle" type="flex">
                            <el-col :span="2"></el-col>
                            <el-col>{{$t("m.customer.suihoufabu")}}</el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="4">
                        <el-row align="middle" type="flex">
                            <el-col :span="2"></el-col>
                            <el-col>{{$t("m.customer.zuihoubanben")}}</el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row align="middle" type="flex" v-for="(item,index) in miniProList" :key="index" style="line-height: 60px;">
                    <el-col :span="5" type="flex">
                        <el-row align="middle" type="flex">
                            <el-col :span="2"></el-col>
                            <el-col style="display: flex;align-items: center">
                                <img v-if="item.logoUrl!=null&&item.logoUrl.length>0" :src="imageUrl(item.logoUrl)" class="photoUrl"/>
                                <div v-else class="photoUrl">
                                    <img src="../../../assets/edit-imgs/making/default-icon.png" />
                                </div>
                                {{item.name}}
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="5">
                        <el-row align="middle" type="flex">
                            <el-col :span="2"></el-col>
                            <el-col>
                                <span v-if="item.createTime">{{item.createTime}}</span>
                                <span v-else>-</span>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="5">
                        <el-row align="middle" type="flex">
                            <el-col :span="2"></el-col>
                            <el-col>
                                <span v-if="item.role!=null">{{miniRoleList[item.role]}}</span>
                                <span v-else>-</span>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="5">
                        <el-row align="middle" type="flex">
                            <el-col :span="2"></el-col>
                            <el-col>
                                <span v-if="item.wechatReleaseTime">{{item.wechatReleaseTime}}</span>
                                <span v-else>-</span>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="4">
                        <el-row align="middle" type="flex">
                            <el-col :span="2"></el-col>
                            <el-col>
                                <span v-if="item.version">{{item.version}}</span>
                                <span v-else>-</span>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row type="flex" style="justify-content: flex-end;margin: 15px 0 0;">
                    <el-pagination class="pagination" background
                                   layout="total,prev, pager, next"
                                   :total="miniProTotal" :page-size="5"
                                   @current-change='current_miniProChange' style="margin-top: 10px">
                    </el-pagination>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getAccountInfoById,
        getMiniProListByAccountId,
        getMerchantListByAccountId
    } from 'request/api/client_list.js'
    import {getImageUrl} from "../../../utils/ImageUtils.js";


    export default {
        props:{
            currentId:String,
        },
        data() {
            return {
                result: {},
                pageNum:1,
                //小程序信息
                miniProList:[],
                miniProTotal:0,
                miniPageNum:1,
                //企业信息
                enterpriseList:[],
                enterpriseTotal:0,
                enterpriseNum:1,
                roleList:['所有者','企业成员', '管理者'],
                miniRoleList:["创建者","协作者"],
            }
        },
        created() {
            this.getAccountInfo()
            this.getMiniProList()
            this.getenterpriseList()
        },
        methods: {
            handleReturn(){
                this.$emit('handleReturn')
            },
            imageUrl(url){
                return getImageUrl(url)
            },
            //获取基本信息
            getAccountInfo() {
                getAccountInfoById({
                    merchantAccountId: this.currentId
                }).then(res => {
                    if (res.code == 200) {
                        console.log(233,res)
                        this.result = res.data
                    }
                })
            },
            //小程序信息
            getMiniProList() {
                getMiniProListByAccountId({
                    accountId: this.currentId,
                    pageSize: 5,
                    pageNum: this.miniPageNum
                }).then(res => {
                    if (res.code == 200) {
                        this.miniProList = res.data.list
                        this.miniProTotal = res.data.total
                    }
                })
            },
            current_miniProChange(e){
                this.miniPageNum = e
                this.getMiniProList()
            },
        //    企业信息
            getenterpriseList() {
                getMerchantListByAccountId({
                    accountId: this.currentId,
                    pageSize: 5,
                    pageNum: this.enterpriseNum
                }).then(res => {
                    if (res.code == 200) {
                        this.enterpriseList = res.data.list
                        this.enterpriseTotal = res.data.total
                    }
                })
            },
            current_enterpriseChange(e){
                this.enterpriseNum = e
                this.getenterpriseList()
            },
        },

    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index";
    .table_box {
        width: 100%;
        margin: auto;
    }

    .search_box {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .table-title{
        background: #F6F6F6;
        height: 32px;
        margin-right: 16px;
        border-radius: 4px;
        border-left: 3px solid @color-primary;
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .keyword {
        height: 50px;
        display: flex;
        align-items: center;
        margin-right: 10px;
    }

    .keyword span {
        width: 100px;
        padding-left: 10px;
        font-size: 16px;
        font-weight: 600;
        color: #333333;
    }

    .goods-image-box {
        width: 60px;
        height: 60px;
        align-content: center;
        border-radius: 60px;
        margin-left: 10px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .goods-image-box {
        width: 50px;
        height: 50px;
        align-content: center;
        border-radius: 50px;
        margin-left: 10px;
        background-size: contain;
    }

    .item {
        height: 50px;
        display: flex;
        align-items: center;
    }

    .pagination {
        padding-top: 20px;
        text-align: right;
    }

    .huZhao {
        width: 100%;
        height: 150px;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
    }
    .photoUrl{
        width:40px;
        height: 40px;
        border-radius: 50% 50%;
        vertical-align: middle;
        background: #eee;
        position: relative;
        margin-right: 5px;
        img{
            width: 26px;
            height: 23px;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: -11.5px;
            margin-left: -13px;
        }
    }
    .whiteClass{
        display: flex;
        flex-wrap: nowrap;
        span:nth-of-type(1){
            white-space: nowrap;
        }
    }
    .textOver{
        display: inline-block;
        line-height: 20px;
        width: 100%;
        margin-top: 15px;
    }
</style>
